import axios from "axios";
import { EnhancedCommerceTransforms } from "spot-types/vendor/enhanced-commerce/EnhancedCommerceTransforms";
import { PetQuote } from "spot-types/entities/PetQuote";

export interface BuilderEventTrackingData {
    eventName: string;
    orderId: string;
    builderSessionId: string;
    builderVisitorId: string;
}

export interface BuilderConversionTrackingData {
    amount: number;
    orderId: string;
    currency: string;
    builderSessionId: string;
    builderVisitorId: string;
}

export class TrackingUtils {
    static buildTrackingPayload(petQuote: PetQuote) {
        const payload = EnhancedCommerceTransforms.petOrderToSpotEnhancedCommerceOrderEvent(petQuote);
        return payload;
    }
    static async trackBuilderEvent(payload: BuilderEventTrackingData) {
        return await axios.post("/api/trackBuilderEvent/", payload);
    }
    static async trackBuilderConversion(payload: BuilderConversionTrackingData) {
        return await axios.post("/api/trackBuilderConversion/", payload);
    }
}
