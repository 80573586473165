import { cn } from "../utils";

interface IndexIndicatorProps {
    index: number;
    label: string;
    handleClick: (index: number) => void;
    className?: string;
}

function IndexIndicator(props: IndexIndicatorProps) {
    const { handleClick, index, label, className } = props;
    const adjustedIndex = index + 1;
    const defaultClasses = "h-2 w-8 rounded-full bg-background-brand-quaternary";
    return <button type="button" className={cn(defaultClasses, className)} aria-label={`Show ${label} ${adjustedIndex}`} onClick={() => handleClick(index)}></button>;
}

export default IndexIndicator;
